
/* @import must be at top of file, otherwise CSS will not work */
@import url("//hello.myfonts.net/count/39267b");


@font-face {font-family: 'CommutersSans-SemiBold';src: url('./fonts/webfonts/39267B_0_0.eot');src: url('./fonts/webfonts/39267B_0_0.eot?#iefix') format('embedded-opentype'),url('./fonts/webfonts/39267B_0_0.woff2') format('woff2'),url('./fonts/webfonts/39267B_0_0.woff') format('woff'),url('/fonts/webfonts/39267B_0_0.ttf') format('truetype');}


@font-face {font-family: 'CommutersSans-Heavy';src: url('./fonts/webfonts/39267B_1_0.eot');src: url('./fonts/webfonts/39267B_1_0.eot?#iefix') format('embedded-opentype'),url('./fonts/webfonts/39267B_1_0.woff2') format('woff2'),url('./fonts/webfonts/39267B_1_0.woff') format('woff'),url('/fonts/webfonts/39267B_1_0.ttf') format('truetype');}


body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  font-family: 'CommutersSans-SemiBold';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: none;
}

*{
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
  color: white;
}

h1{
  color: #8f313b;
  text-transform: uppercase;
  line-height: 1.2;
  letter-spacing: 2.5px;
  font-size: 16px;
}
