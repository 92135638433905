@import url(//hello.myfonts.net/count/39267b);

/* @import must be at top of file, otherwise CSS will not work */


@font-face {font-family: 'CommutersSans-SemiBold';src: url(/static/media/39267B_0_0.7d069359.eot);src: url(/static/media/39267B_0_0.7d069359.eot?#iefix) format('embedded-opentype'),url(/static/media/39267B_0_0.27fa39b4.woff2) format('woff2'),url(/static/media/39267B_0_0.38d54ed5.woff) format('woff'),url('/fonts/webfonts/39267B_0_0.ttf') format('truetype');}


@font-face {font-family: 'CommutersSans-Heavy';src: url(/static/media/39267B_1_0.919fa894.eot);src: url(/static/media/39267B_1_0.919fa894.eot?#iefix) format('embedded-opentype'),url(/static/media/39267B_1_0.c6bd375d.woff2) format('woff2'),url(/static/media/39267B_1_0.b977bc9f.woff) format('woff'),url('/fonts/webfonts/39267B_1_0.ttf') format('truetype');}


body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  font-family: 'CommutersSans-SemiBold';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: none;
}

*{
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
  color: white;
}

h1{
  color: #8f313b;
  text-transform: uppercase;
  line-height: 1.2;
  letter-spacing: 2.5px;
  font-size: 16px;
}

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

